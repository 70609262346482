<template>
    <main>
      <div
        class="modal fade"
        id="modalAsignacionAreas"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Registro de asignacion de Areas de Laboratorio
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card-waves ">
                <div class="card-header bg-light text-dark"><i class="fa fa-book fa-fw text-primary"></i>Datos</div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                      <label class="form-label">Fecha</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="asignacionareas.fecha"
                        :class="{
                          'is-invalid':
                            $v.asignacionareas.fecha.$error && $v.asignacionareas.fecha.$dirty,
                        }"
                      />
                    </div>

                     
                    
                  </div>
                </div>
              </div>
              
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="save()"
              >
                Guardar<i class="fa fa-save fa-fw"></i>
              </button>
              <button
                type="button"
                class="btn btn-light btn-sm"
                @click="cerrar()"
              >
                Cerrar<i class="fa fa-times-circle fa-fw"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  <script>
  

  import asignacionAreasService from '../../../../../../services/asignacionAreasService';
  
  import Toast from "../../../../../../components/common/utilities/toast";
  import { required } from "vuelidate/lib/validators";
  import $ from "jquery";
  
  export default {
    data() {
      return {
        asignacionareas: {
          id: "",
          fecha: "",
          estado: ""
        },
        
        type: "store",
      };
    },
    validations() {
      return {
        asignacionareas: {
          fecha: {required}
        },
        
      };
    },
    methods: {
      
      async save() {
        try {
          this.$v.asignacionareas.$touch();
           
          if (this.$v.asignacionareas.$invalid) {
            return;
          }

          this.LoaderSpinnerShow();

          if (this.type === "store") {
            await asignacionAreasService.store(this.asignacionareas);
            this.type = "store";
            this.limpiarCampos();
          }
  
          this.LoaderSpinnerHide();
  
          Toast.fire({
            icon: "success",
            title: "Datos guardados con exito",
          });

          this.$emit("cargar-asignacion-areas");
  
          $("#modalAsignacionAreas").modal("hide");
          
        } catch (err) {
          console.error(err);
          this.LoaderSpinnerHide();
          Toast.fire({
            icon: "error",
            title: "Ocurrio un error al procesar la solicitud",
          });
        }
      },
      async limpiarCampos() {
      this.asignacionareas.fecha = "";
 
    },
      
      cerrar() {
        this.limpiarCampos();
        $("#modalAsignacionAreas").modal("hide");
      },
      
    },
  };
  </script>
  